import React, { FunctionComponent, useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../Link";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SocialShare from "../../components/SocialShare";
import { ImageInterface, PortableText, SanityFeaturePromoDoubleInterface } from "../../types/SanityTypes";
import "./styles.scss";
import { Container } from "react-bootstrap";
import ModalBlock, { ModalBlockInterface } from "../../components/Modal";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { withPrefix } from "gatsby";
import { getLocalizedPath } from "../../utils/gatsbyBuild";

type FeaturePromoDoubleInterface = {
  searchLink?: string;
  share: boolean;
  productLandingPageSlug?: string;
  language?: string;
  // For Preview
  textBody?: PortableText;
  featurePromoImage1?: ImageInterface;
  featurePromoImage2?: ImageInterface;
} & SanityFeaturePromoDoubleInterface;

export const PureFeaturePromoDouble: FunctionComponent<FeaturePromoDoubleInterface> = ({
  heading,
  bgColor,
  _rawTextBody,
  _rawFeaturePromoImage1,
  _rawFeaturePromoImage2,
  textBody,
  featurePromoImage1,
  featurePromoImage2,
  ctaLabel,
  searchLink,
  ctaLink,
  share,
  productLandingPageSlug,
  language,
  theme,
  imageHeight1,
  imageWidth1,
  imageHeight2,
  imageWidth2
}) => {
  const [showModal, setShowModal] = useState(false);
  const { sanityId, sanityDataset, locale, siteUrl } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const text = _rawTextBody || textBody;
  const imageOne = _rawFeaturePromoImage1 || featurePromoImage1;
  const imageTwo = _rawFeaturePromoImage2 || featurePromoImage2;

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const createThemeClassName = (className: string | undefined) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  const parentPath = ctaLink?.parentSection?.parentSection
    ? `${ctaLink?.parentSection?.parentSection.slug.current}/${ctaLink?.parentSection?.slug.current}`
    : ctaLink?.parentSection?.slug.current;

  const getCtaURL = () => {
    if (ctaLink?.__typename === "SanityProduct" || ctaLink?.__typename === "SanityProductLinePage") {
      return `/${productLandingPageSlug}/${ctaLink.slug?.current}/`;
    } else if (ctaLink?.__typename === "SanityAnchorElement") {
      return `#${ctaLink?.fragmentId}`;
    } else if (ctaLink?.__typename === "SanityArticle" && ctaLink.parentSection) {
      return `/${parentPath}/${ctaLink.slug?.current}/`;
    } else {
      return `/${ctaLink?.slug?.current}/`;
    }
  };

  const localizedCtaUrl = getLocalizedPath(getCtaURL(), ctaLink?._id);

  const renderCTA = () => {
    if (ctaLink?.__typename === "SanityModalCTABlock" || ctaLink?._type === "modalCTABlock") {
      return (
        <>
          <button className="btn-primary" onClick={handleShow} id="featurePromoDoubleBlockLink">
            {ctaLabel}
          </button>
          <ModalBlock data={ctaLink as ModalBlockInterface["data"]} showModal={showModal} handleClose={handleClose} />
        </>
      );
    }
    return (
      <Link
        _id={ctaLink?._id}
        to={`${getCtaURL() as string}`}
        className="btn-primary"
        {...(ctaLink?.__typename === "SanityArticle" && { "data-analytics-event4": "" })}
      >
        {ctaLabel}
      </Link>
    );
  };

  return (
    <section
      data-testid="feature-promo-double"
      className={`feature-promo-double ${theme && createThemeClassName(theme.name)}`}
    >
      <Container fluid>
        <div className="primary-image-position">
          {imageOne && (
            <picture>
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder
                    .image(imageOne)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth1 || 1000)
                    .height(imageHeight1 || 800)
                    .format("webp")
                    .url() as string
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder
                    .image(imageOne)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth1 || 800)
                    .height(imageHeight1 || 600)
                    .format("webp")
                    .url() as string
                }
              />
              <img
                className="img-fluid"
                src={
                  urlBuilder
                    .image(imageOne)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth1 || 500)
                    .height(imageHeight1 || 400)
                    .format("webp")
                    .url() as string
                }
                alt={imageOne.alt}
                loading={"lazy"}
              />
            </picture>
          )}
        </div>
        <div className="secondary-image-position">
          {imageTwo && (
            <picture>
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder
                    .image(imageTwo)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth2 || 600)
                    .height(imageHeight2 || 1000)
                    .format("webp")
                    .url() as string
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder
                    .image(imageTwo)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth2 || 500)
                    .height(imageHeight2 || 800)
                    .format("webp")
                    .url() as string
                }
              />
              <img
                className="img-fluid"
                src={
                  urlBuilder
                    .image(imageTwo)
                    .auto("format")
                    .quality(80)
                    .width(imageWidth2 || 400)
                    .height(imageHeight2 || 700)
                    .format("webp")
                    .url() as string
                }
                alt={imageTwo.alt}
                loading={"lazy"}
              />
            </picture>
          )}
        </div>
        <div className="text-container">
          <div className="text-block">
            {heading && <h2 style={{ color: bgColor.value }}>{heading}</h2>}
            {text && (
              <div style={{ color: bgColor.value }}>
                <BlockContent blocks={text} />
              </div>
            )}
            {ctaLink && ctaLabel && renderCTA()}
            {searchLink && (
              <Link _id={ctaLink?._id} to={searchLink} className="btn-primary">
                {ctaLabel}
              </Link>
            )}
            <div style={{ lineHeight: "0", marginTop: "10px" }}>
              {share && (
                <SocialShare
                  url={`${siteUrl}${withPrefix(localizedCtaUrl || (searchLink as string))}`}
                  locale={locale}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const FeaturePromoDouble: FunctionComponent<FeaturePromoDoubleInterface> = ({
  featureName,
  heading,
  bgColor,
  _rawTextBody,
  _rawFeaturePromoImage1,
  _rawFeaturePromoImage2,
  textBody,
  featurePromoImage1,
  featurePromoImage2,
  ctaLabel,
  searchLink,
  ctaLink,
  share,
  theme,
  imageHeight1,
  imageWidth1,
  imageHeight2,
  imageWidth2
}) => {
  const { sanityProductLandingPage, language } = useContext(LocalizedContext);

  return (
    <PureFeaturePromoDouble
      ctaLink={ctaLink}
      heading={heading}
      bgColor={bgColor}
      _rawTextBody={_rawTextBody}
      _rawFeaturePromoImage1={_rawFeaturePromoImage1}
      _rawFeaturePromoImage2={_rawFeaturePromoImage2}
      textBody={textBody}
      featurePromoImage1={featurePromoImage1}
      featurePromoImage2={featurePromoImage2}
      ctaLabel={ctaLabel}
      searchLink={searchLink}
      share={share}
      productLandingPageSlug={sanityProductLandingPage?.slug.current}
      language={language}
      featureName={featureName}
      theme={theme}
      imageHeight1={imageHeight1}
      imageWidth1={imageWidth1}
      imageHeight2={imageHeight2}
      imageWidth2={imageWidth2}
    />
  );
};

export default FeaturePromoDouble;
